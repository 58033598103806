<template>
    <MainLayout>
        <div class="account-page">
            <div class="container">
                <div class="personal-info">
                    <h1 class="block-title text-h2 text-bold text-center">Особистий профіль</h1>
                    <UserImageComponent @update-avatar="updateAvatar" />
                    <InputComponent
                        v-model="authStore.user.name"
                        label="Імʼя"
                        placeholder="Імʼя"
                        type="text"
                        :errors="v$.authStore.user.name.$errors"
                    />
                    <InputComponent
                        v-model="authStore.user.surname"
                        label="Прізвище"
                        placeholder="Прізвище"
                        type="text"
                        :errors="v$.authStore.user.surname.$errors"
                    />
                    <InputComponent
                        v-model="authStore.user.phone"
                        label="Номер телефону"
                        placeholder="Номер телефону"
                        type="tel"
                        :errors="v$.authStore.user.phone.$errors"
                    />
                    <InputComponent
                        v-model="authStore.user.email"
                        label="E-mail"
                        placeholder="E-mail"
                        type="email"
                        :errors="v$.authStore.user.email.$errors"
                    />
                    <ButtonComponent
                        class="save-changes-btn"
                        btn-style="gradient"
                        text="Зберегти зміни"
                        :disabled="!checkPersonalDataChange"
                        @click="savePersonalInfo"
                    />
                </div>

                <div v-if="documentsList.length" class="documents-info">
                    <h2 class="block-title text-h2 text-bold text-center">Документи</h2>
                    <ImagesComponent
                        v-for="(group, index) in documentsList"
                        :key="index"
                        :block-title="group?.group_title"
                        :block-data="group?.document_images"
                        title-class="text-h4"
                        form-data-key="documents[]"
                        @load-image="(formData) => loadImage(formData, group.group_id)"
                    />
                </div>

                <div class="logout-wrap">
                    <ButtonComponent
                        btn-style="gradient"
                        text="Вийти з акаунту"
                        @click="authStore.logout()"
                    />
                </div>

                <ImageDeleteModal modal-title="Видалити фото?" @submit-delete="submitImageDelete" />
            </div>
        </div>
        <Transition name="fade">
            <LoaderComponent v-if="showLoader" />
        </Transition>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import InputComponent from '@/components/form/InputComponent.vue'
import UserImageComponent from '@/components/form/UserImageComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'
import { email, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { useModalStore } from '@/stores/index.js'
import { useAuthStore } from '@/stores'
import { useVuelidate } from '@vuelidate/core'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import ImagesComponent from '@/components/ImagesComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
    name: 'AccountView',
    components: {
        LoaderComponent,
        ImagesComponent,
        ImageDeleteModal,
        ButtonComponent,
        UserImageComponent,
        InputComponent,
        MainLayout
    },
    setup() {
        const authStore = useAuthStore()
        const modalStore = useModalStore()
        const v$ = useVuelidate({ $scope: false })

        return { authStore, modalStore, v$ }
    },
    data() {
        return {
            initialPersonalData: {},
            documentsList: [],
            filesList: [],
            loadedAvatar: null,
            showLoader: true
        }
    },
    validations() {
        return {
            authStore: {
                user: {
                    name: {
                        required: helpers.withMessage("Ім'я є обов'язковим", required),
                        min: helpers.withMessage("Ім'я занадто коротке", minLength(3)),
                        max: helpers.withMessage("Ім'я занадто довге", maxLength(255))
                    },
                    surname: {
                        required: helpers.withMessage("Прізвище є обов'язковим", required),
                        min: helpers.withMessage('Прізвище занадто коротке', minLength(3)),
                        max: helpers.withMessage('Прізвище занадто довге', maxLength(255))
                    },
                    email: {
                        required: helpers.withMessage("Email є обов'язковим", required),
                        min: helpers.withMessage('Електронна пошта занадто коротка', minLength(8)),
                        max: helpers.withMessage('Електронна пошта занадто довга', maxLength(255)),
                        email: helpers.withMessage('Недійсна адреса електронної пошти', email)
                    },
                    phone: {
                        required: helpers.withMessage("Номер телефону є обов'язковим", required),
                        min: helpers.withMessage('Номер телефону занадто короткий', minLength(9)),
                        max: helpers.withMessage('Номер телефону занадто довгий', maxLength(20))
                    }
                }
            }
        }
    },
    computed: {
        checkPersonalDataChange() {
            return (
                this.initialPersonalData.name !== this.authStore.user.name ||
                this.initialPersonalData.surname !== this.authStore.user.surname ||
                this.initialPersonalData.phone !== this.authStore.user.phone ||
                this.initialPersonalData.email !== this.authStore.user.email ||
                this.loadedAvatar !== null
            )
        }
    },
    created() {
        this.setInitialPersonalData()
    },
    mounted() {
        this.loadPersonalData()
    },
    methods: {
        async loadPersonalData(data = []) {
            if (data.length) {
                this.documentsList = data
            } else {
                fetchWrapper
                    .post('/personal-cabinet/load-info', {})
                    .then((response) => {
                        this.documentsList = response.documents
                    })
                    .finally(() => {
                        this.showLoader = false
                    })
            }
        },

        setInitialPersonalData() {
            this.initialPersonalData = {
                name: this.authStore.user.name,
                surname: this.authStore.user.surname,
                phone: this.authStore.user.phone,
                email: this.authStore.user.email
            }

            this.loadedAvatar = null
        },

        updateAvatar(file) {
            this.loadedAvatar = file
        },

        savePersonalInfo() {
            this.v$.$reset()
            this.v$.authStore.user.$validate()
            if (!this.v$.authStore.user.$error) {
                const formData = new FormData()

                if (this.loadedAvatar) {
                    formData.append('avatar', this.loadedAvatar)
                }

                formData.append('name', this.authStore.user.name)
                formData.append('surname', this.authStore.user.surname)
                formData.append('phone', this.authStore.user.phone)
                formData.append('email', this.authStore.user.email)

                fetchWrapper
                    .post('/personal-cabinet/update-account', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(() => {
                        this.$toast.success('Профіль оновлено')
                        this.setInitialPersonalData()
                    })
            }
        },

        loadImage(formData, groupId) {
            formData.append('user_document_group_id', groupId)

            fetchWrapper
                .post('/personal-cabinet/create-documents', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.loadPersonalData(response.documents)
                    this.$toast.success('Документи додано')
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        submitImageDelete() {
            fetchWrapper
                .delete(
                    `/personal-cabinet/delete-document/${this.modalStore.imageConfirmationModal.itemIdForDelete}`,
                    {}
                )
                .then((response) => {
                    this.loadPersonalData(response.documents)
                    this.$toast.success('Документ видалено')
                })
        }
    }
}
</script>

<style scoped lang="scss">
.account-page {
    padding: 1.25rem 0 0;
}

.personal-info {
    margin-bottom: 2.5rem;

    .save-changes-btn {
        margin-top: 2rem;
    }
}

.logout-wrap {
    padding-top: 2rem;
}
</style>
