<template>
    <GDialog
        v-model="modalStore.addPassengerModal.show"
        max-width="500"
        border-radius="10"
        @update:model-value="hideModal"
    >
        <div v-if="modalStore.addPassengerModal.show" class="add-passenger-modal">
            <div class="container">
                <div class="modal-body">
                    <div class="modal-info">
                        <div class="section-title text-h2 text-bold">Деталі пасажира</div>

                        <InputComponent
                            v-model="passengerData.internal_id"
                            type="text"
                            label="Внутрішній ідентифікатор"
                            placeholder="Внутрішній ідентифікатор"
                        />
                        <div class="select-wrap">
                            <div class="select-label text-h5 text-grey">Мобільний телефон</div>
                            <ClientSearchComponent
                                v-model="passengerData.passenger.phone"
                                @client-select="handleClientSelect"
                                @client-clear="handleClientClear"
                                @phone-validation="onPhoneValidation"
                            />
                        </div>
                        <InputComponent
                            v-model="passengerData.passenger.name"
                            type="text"
                            label="Імʼя"
                            placeholder="Імʼя"
                        />
                        <InputComponent
                            v-model="passengerData.passenger.surname"
                            type="text"
                            label="Прізвище"
                            placeholder="Прізвище"
                        />

                        <div class="select-wrap">
                            <div class="select-label text-h5 text-grey">Напрям</div>
                            <Multiselect
                                ref="directionSelect"
                                v-model="passengerData.trip_direction_id"
                                placeholder="Оберіть напрям"
                                value-prop="id"
                                :options="directionOptions"
                                :resolve-on-load="true"
                                :close-on-select="true"
                                @change="handleDirectionChange"
                            >
                                <template #caret>
                                    <CollapseToggler :opened="false" />
                                </template>

                                <template #singlelabel="{ value }">
                                    <div class="value-text">
                                        <span class="text-h3">{{ value.name }}</span>
                                    </div>
                                </template>
                                <template #option="{ option }">
                                    <div class="option-text">
                                        <span class="text-h4">{{ option.name }}</span>
                                    </div>
                                </template>

                                <template #nooptions>
                                    <div class="no-options-drop text-h5">
                                        Не знайдено жодного напряму
                                    </div>
                                </template>
                            </Multiselect>
                        </div>

                        <div class="select-wrap">
                            <div class="select-label text-h5 text-grey">Оберіть кінцеву точку</div>
                            <Multiselect
                                ref="arrivalPointSelect"
                                v-model="passengerData.arrival.route_point_id"
                                placeholder="Оберіть кінцеву точку"
                                value-prop="id"
                                :options="activeRouteStore.routeData.points"
                                :resolve-on-load="true"
                                :close-on-select="true"
                                @change="handlePointChange"
                            >
                                <template #caret>
                                    <CollapseToggler :opened="false" />
                                </template>

                                <template #singlelabel="{ value }">
                                    <div class="value-text">
                                        <span class="text-h3">{{ value.address }}</span>
                                    </div>
                                </template>
                                <template #option="{ option }">
                                    <div class="option-text">
                                        <span class="text-h4">{{ option.address }}</span>
                                    </div>
                                </template>

                                <template #nooptions>
                                    <div class="no-options-drop text-h5">
                                        Не знайдено жодної точки
                                    </div>
                                </template>
                            </Multiselect>
                        </div>

                        <div class="select-wrap">
                            <div class="select-label text-h5 text-grey">Оберіть дію на точці</div>
                            <Multiselect
                                ref="arrivalActionSelect"
                                v-model="passengerData.arrival.action_type"
                                placeholder="Оберіть дію на точці"
                                value-prop="key"
                                :options="availablePointActions"
                                :resolve-on-load="true"
                                :close-on-select="true"
                                @change="handleActionChange"
                            >
                                <template #caret>
                                    <CollapseToggler :opened="false" />
                                </template>

                                <template #singlelabel="{ value }">
                                    <div class="value-text">
                                        <img :src="value.icon" />
                                        <span class="text-h3">{{ value.label }}</span>
                                    </div>
                                </template>
                                <template #option="{ option }">
                                    <div class="option-text">
                                        <img :src="option.icon" />
                                        <span class="text-h4">{{ option.label }}</span>
                                    </div>
                                </template>

                                <template #nooptions>
                                    <div class="no-options-drop text-h5">
                                        Не знайдено жодної дії
                                    </div>
                                </template>
                            </Multiselect>
                        </div>

                        <div class="status-row">
                            <div class="text-h5 text-grey">Статус оплати:</div>
                            <StatusComponent
                                :status-options="paymentStatusOptions"
                                :current-status="currentPaymentStatus"
                                @update-status="updatePaymentStatus"
                            />
                        </div>

                        <ImagesComponent
                            title-class="text-h3 text-bold"
                            form-data-key="images[]"
                            :block-data="imagesPreview"
                            block-title="Фото"
                            @load-image="loadImage"
                        />

                        <div class="comment-wrap">
                            <textarea
                                v-model="passengerData.comment"
                                placeholder="Поле для приміток"
                                class="comment-input text-h3"
                            >
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="modal-buttons shadow-sm">
                    <div class="container">
                        <ButtonComponent
                            class="cancel-btn"
                            btn-style="grey"
                            text="Скасувати"
                            @click="hideModal"
                        />

                        <ButtonComponent
                            btn-style="gradient"
                            class="text-h2"
                            text="Створити"
                            @click="submitParcelCreate"
                        />
                    </div>
                </div>

                <ImageDeleteModal modal-title="Видалити фото?" @submit-delete="submitImageDelete" />
            </div>
        </div>
    </GDialog>
</template>

<script>
import InputComponent from '@/components/form/InputComponent.vue'
import CollapseToggler from '@/components/CollapseToggler.vue'
import StatusComponent from '@/components/StatusComponent.vue'
import ImagesComponent from '@/components/ImagesComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'
import Multiselect from '@vueform/multiselect'
import actionMixin from '@/mixins/actionMixin.js'
import statusesMixin from '@/mixins/statusesMixin.js'
import { useActiveRouteStore, useModalStore } from '@/stores/index.js'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import ClientSearchComponent from '@/components/form/ClientSearchComponent.vue'

export default {
    name: 'AddPassengerModal',
    components: {
        ClientSearchComponent,
        ImageDeleteModal,
        ImagesComponent,
        StatusComponent,
        ButtonComponent,
        InputComponent,
        Multiselect,
        CollapseToggler
    },
    mixins: [actionMixin, statusesMixin],
    emits: ['hide-overlay'],
    setup() {
        const activeRouteStore = useActiveRouteStore()
        const modalStore = useModalStore()

        return { activeRouteStore, modalStore }
    },
    data() {
        return {
            passengerData: {
                internal_id: null,
                passenger: {
                    name: '',
                    surname: '',
                    phone: ''
                },
                trip_direction_id: null,
                arrival: {
                    route_point_id: null,
                    action_type: ''
                },
                payment_status: 'Not paid',
                comment: '',
                images: []
            },
            imagesPreview: [],
            passengerPhoneValid: true
        }
    },
    computed: {
        currentPaymentStatus() {
            return this.paymentStatusOptions.filter((status) => {
                return status.value === this.passengerData.payment_status
            })[0]
        },

        availablePointActions() {
            return this.actionsOptionsArray.filter((item) => {
                if (item.key === 'finish_trip' || item.key === 'finish_transfer') {
                    return item
                }
            })
        }
    },
    methods: {
        onPhoneValidation(status) {
            this.passengerPhoneValid = status
        },

        handleClientSelect(option) {
            this.passengerData.passenger = { ...option }
        },

        handleClientClear() {
            this.passengerData.passenger.phone = ''
        },

        updatePaymentStatus(status) {
            this.passengerData.payment_status = status
        },

        handlePointChange() {
            this.$refs.arrivalPointSelect.blur()
        },

        handleDirectionChange() {
            this.$refs.directionSelect.blur()
        },

        handleActionChange() {
            this.$refs.arrivalActionSelect.blur()
        },

        loadImage(formData) {
            this.convertFilesToURLs(formData)
        },

        convertFilesToURLs(formData) {
            return new Promise(() => {
                const fileURLs = []
                const files = []
                const promises = []

                formData.getAll('images[]').forEach((file) => {
                    const reader = new FileReader()

                    const promise = new Promise((resolveReader, rejectReader) => {
                        reader.onload = function (event) {
                            fileURLs.push({ url: event.target.result })
                            files.push(file)
                            resolveReader()
                        }
                        reader.onerror = function (error) {
                            rejectReader(error)
                        }
                    })

                    reader.readAsDataURL(file)
                    promises.push(promise)
                })

                Promise.all(promises).then(() => {
                    this.imagesPreview.push(...fileURLs)
                    this.imagesPreview.forEach((image, index) => {
                        image.id = index
                    })

                    this.passengerData.images.push(...files)
                })
            })
        },

        submitImageDelete(itemIndex) {
            this.imagesPreview.splice(itemIndex, 1)
            this.passengerData.images.splice(itemIndex, 1)
            this.imagesPreview.forEach((image, index) => {
                image.id = index
            })
        },

        async directionOptions() {
            const options = await fetchWrapper.post(
                `${import.meta.env.VITE_DRIVER_URL}/trip-directions/load-list`
            )

            if (!options.length) {
                return
            }

            return options
        },

        submitParcelCreate() {
            if (!this.passengerPhoneValid) {
                this.$toast.error('Не вірний формат номеру телефону')

                return
            }

            fetchWrapper
                .post('/bookings/store', this.passengerData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.activeRouteStore.updateActiveRoute(response.route)
                    this.hideModal()
                    this.$toast.success('Пасажира добавлено')
                    this.openEntityModal(response.entity)
                })
        },

        openEntityModal(entity) {
            this.modalStore.entityModal.show = true
            this.modalStore.entityModal.entityType = entity.type
            this.modalStore.entityModal.entityId = entity.id
        },

        hideModal() {
            this.modalStore.addPassengerModal.show = false
            this.$emit('hide-overlay')
            this.passengerData = {
                internal_id: null,
                passenger: {
                    name: '',
                    surname: '',
                    phone: ''
                },

                arrival: {
                    route_point_id: null,
                    action_type: ''
                },
                payment_status: 'Not paid',
                comment: '',
                images: []
            }
            this.imagesPreview = []
        }
    }
}
</script>

<style scoped lang="scss">
.add-passenger-modal {
    .section-title {
        margin-bottom: 1rem;
    }

    .status-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 0;
        border-top: 1px solid $grey-light;
        border-bottom: 1px solid $grey-light;
    }

    .comment-wrap {
        padding: 1.25rem 0 0 0;
        margin-bottom: 1.25rem;

        .comment-input {
            padding: 0.7rem 1rem;
            width: 100%;
            height: 6rem;
            border: 1px solid $grey-light;
            border-radius: 0.7rem;
            appearance: none;
            overflow: hidden;
            resize: none;
        }
    }
}

.select-wrap {
    display: flex;
    flex-direction: column;
}

.value-text,
.option-text {
    display: flex;
    align-items: center;

    img {
        margin-right: 0.25rem;
    }
}

.no-options-drop {
    padding: 0.5rem 1rem;
}
</style>
