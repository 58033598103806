<template>
    <GDialog
        :model-value="show"
        max-width="500"
        border-radius="10"
        @update:model-value="cancelAction"
    >
        <div class="modal-body">
            <div class="modal-title text-h1 text-bold">{{ modalTitle }}</div>
            <div class="modal-description text-h2">{{ modalDescription }}</div>
            <div class="modal-buttons">
                <ButtonComponent
                    class="cancel-btn"
                    btn-style="grey"
                    text="Скасувати"
                    @click="cancelAction"
                />
                <ButtonComponent
                    class="save-btn"
                    btn-style="gradient"
                    text="Видалити"
                    @click="submitAction"
                />
            </div>
        </div>
    </GDialog>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
    name: 'ConfirmationModal',
    components: { ButtonComponent },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            required: true
        },
        modalDescription: {
            type: String,
            default: 'Цю дію не можливо відмінити'
        }
    },
    emits: ['submit-action', 'cancel-action'],
    methods: {
        submitAction() {
            this.$emit('submit-action')
        },

        cancelAction() {
            this.$emit('cancel-action')
        }
    }
}
</script>

<style scoped lang="scss">
.modal-title,
.modal-description {
    text-align: center;
}
</style>
