<template>
    <div class="form-group" :class="{ invalid: errors?.length, disabled: disabled }">
        <label v-if="label.length" class="input-label text-h5 text-grey">{{ label }}</label>
        <input
            :type="currentInputType"
            :value="inputValue"
            class="text-h3"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="handleInput"
        />
        <div
            v-if="type === 'password'"
            class="password-toggler"
            :class="{ toggled: !passwordVisible }"
            @click="togglePasswordVisibility"
        >
            <img src="@/assets/images/eye-closed.svg" class="password-eye eye-closed" alt="" />
            <img src="@/assets/images/eye-opened.svg" class="password-eye eye-opened" alt="" />
        </div>
        <div v-if="errors?.length" class="input-error">
            <span class="text-h5">
                {{ errors[0]?.$message }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputComponent',
    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: () => {}
        },
        mask: {
            type: Object,
            default: () => ({
                number: false,
                precision: 0
            })
        }
    },
    data() {
        return {
            inputValue: this.modelValue,
            passwordVisible: false,
            isUpdatingManually: false
        }
    },
    computed: {
        currentInputType() {
            return this.type === 'password' && this.passwordVisible ? 'text' : this.type
        }
    },
    watch: {
        modelValue(newValue) {
            if (this.isUpdatingManually) {
                this.isUpdatingManually = false

                return
            }

            let value = newValue

            if (this.mask.number) {
                if (value === null) {
                    this.inputValue = value

                    return
                }

                const precision = this.mask.precision ?? 0

                value = value.toString().replace('.', ',')

                if (precision === 0) {
                    value = value.replace(/[^0-9]/g, '')
                    value = value.replace(/^0+(?!$)/, '')
                } else {
                    value = value.replace(/[^0-9,]/g, '')
                    value = value.replace(/(,.*?),.*/g, '$1')
                    value = value.replace(/^0+(?=\d)/, '')
                    value = value.replace(/^,/, '0,')
                    value = value.replace(new RegExp(`^(\\d*,?\\d{0,${precision}}).*`), '$1')
                }
            }

            this.inputValue = value
        }
    },
    methods: {
        handleInput(event) {
            this.isUpdatingManually = true
            let value = event.target.value

            if (this.mask.number) {
                const precision = this.mask.precision ?? 0

                if (precision === 0) {
                    value = value.replace(/[^0-9]/g, '')
                    value = value.replace(/^0+(?!$)/, '')
                } else {
                    value = value.replace(/[^0-9,]/g, '')
                    value = value.replace(/(,.*?),.*/g, '$1')
                    value = value.replace(/^0+(?=\d)/, '')
                    value = value.replace(/^,/, '0,')
                    value = value.replace(new RegExp(`^(\\d*,?\\d{0,${precision}}).*`), '$1')
                }
            }

            this.inputValue = value
            this.$emit('update:modelValue', this.inputValue)

            if (this.mask.number) {
                event.target.value = value
            }
        },

        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible
        }
    }
}
</script>

<style scoped lang="scss">
.form-group {
    position: relative;
    margin-bottom: 1.25rem;
    width: 100%;

    &.disabled {
        input {
            background: #f3f4f6;
        }
    }

    &.invalid {
        input {
            border: 1px solid $red;
        }
    }

    input {
        width: 100%;
        height: 3rem;
        padding: 1rem;
        border-radius: 0.5rem;
        outline: none;
        background: $grey-light;
        border: 1px solid $grey-light;

        &.toggled-input {
            padding-right: 4rem;
        }

        &::placeholder {
            color: $grey;
        }

        &:disabled {
            user-select: none;
        }

        &:focus {
            border: 1px solid $yellow;
            background: $white;
        }
    }

    .input-label {
        display: flex;
    }

    .password-toggler {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(calc(-50% + 0.15rem));

        &.toggled {
            .eye-closed {
                display: block;
            }

            .eye-opened {
                display: none;
            }
        }

        .password-eye {
            width: 1.5rem;
            height: 1.5rem;
        }

        .eye-closed {
            display: none;
        }
    }

    .input-error {
        position: absolute;
        top: 4rem;
        padding: 0 0.25rem;

        span {
            color: $red;
            margin-right: 0.5rem;
        }
    }
}
</style>
