<template>
    <MainLayout>
        <div class="home-page">
            <div class="container">
                <div class="page-header">
                    <h1 class="block-title text-h2 text-bold">
                        Привіт,
                        <span class="text-h2 text-bold gradient-text">
                            {{ authStore.user.name }}
                        </span>
                    </h1>
                    <router-link :to="{ name: 'searchPage' }" class="search-link">
                        <img src="@/assets/images/search-icon.svg" class="search-btn" alt="" />
                    </router-link>
                </div>
                <div class="current-month-achievements shadow-sm">
                    <div class="achievements-title text-h5">Досягнення за поточний місяць</div>
                    <div class="achievements-list">
                        <div class="achievement-item text-h1 text-bold gradient-text">
                            {{ pageInfo.finished_routes_count }} {{ tripsCountWord }}
                        </div>
                        <div class="achievement-item text-h1 text-bold gradient-text">
                            {{ metersToKilometers }} км.
                        </div>
                    </div>
                </div>
                <router-link :to="{ name: 'myRoutesPage' }" class="my-trips shadow-sm">
                    <img src="@/assets/images/my-trips-icon.svg" alt="" />
                    <span class="text-h3 text-bold">Мої поїздки</span>
                </router-link>

                <div v-if="pageInfo?.nearest_route?.id" class="nearest-route">
                    <div class="nearest-route-title text-h5">Найближча поїздка</div>
                    <RouteComponent
                        v-if="pageInfo?.nearest_route"
                        :route-info="pageInfo?.nearest_route"
                        :extended="true"
                    />
                </div>

                <a :href="`tel:${pageInfo.manager_phone}`" class="contact-link">
                    <img src="@/assets/images/phone-icon.svg" class="contact-icon" alt="" />
                    <span class="contact-text text-h4">Зателефонувати до менеджера</span>
                </a>
            </div>
        </div>
        <Transition name="fade">
            <LoaderComponent v-if="showLoader" />
        </Transition>
    </MainLayout>
</template>

<script>
import { useAuthStore } from '@/stores/index.js'
import MainLayout from '@/layouts/MainLayout.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import RouteComponent from '@/components/RouteComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import PaymentModal from '@/components/modals/PaymentModal.vue'

export default {
    name: 'HomeView',
    components: { PaymentModal, LoaderComponent, RouteComponent, MainLayout },
    setup() {
        const authStore = useAuthStore()

        return {
            authStore
        }
    },
    data() {
        return {
            pageInfo: {},
            tripsCountWord: '',
            showLoader: true
        }
    },
    computed: {
        metersToKilometers() {
            if (this.pageInfo.finished_route_distance) {
                return Math.floor(this.pageInfo.finished_route_distance / 1000)
            } else {
                return 0
            }
        }
    },
    mounted() {
        fetchWrapper
            .post('/main-page/load-info', {})
            .then((response) => {
                this.pageInfo = response
                this.tripsWordFromNum(response.finished_routes_count.toString())
            })
            .finally(() => {
                this.showLoader = false
            })
    },
    methods: {
        tripsWordFromNum(tripsCount) {
            const lastChar = parseInt(tripsCount.charAt(tripsCount.length - 1))

            if (lastChar == 1) {
                this.tripsCountWord = 'поїздка'
            } else if (lastChar >= 2 && lastChar <= 4) {
                this.tripsCountWord = 'поїздки'
            } else {
                this.tripsCountWord = 'поїздок'
            }
        }
    }
}
</script>

<style scoped lang="scss">
.home-page {
    padding: 1.25rem 0 0;
    background: $white;
}

.page-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    .block-title {
        margin-bottom: 0;
    }

    .search-link {
        display: flex;
        margin-left: auto;
        margin-right: 1.25rem;
    }

    .search-btn {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.current-month-achievements {
    padding: 1rem 1.25rem 1.25rem;
    margin-bottom: 1.25rem;
    border-radius: 0.5rem;

    .achievements-title {
        margin-bottom: 0.25rem;
    }

    .achievements-list {
        display: flex;
        justify-content: space-between;
    }
}

.my-trips {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem 1.25rem;
    border-radius: 0.5rem;
    color: $black;
    text-decoration: none;
    margin-bottom: 2rem;

    img {
        margin-right: 0.5rem;
    }
}

.nearest-route-title {
    padding-left: 1rem;
    margin-bottom: 0.8rem;
}

.contact-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;
    text-decoration: none;

    .contact-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }

    .contact-text {
        color: $black;
    }
}
</style>
